@import '@fortawesome/fontawesome-free/scss/fontawesome';

// Basics ----------------
@import "reset";
@import "fonts";
@import "variables";
@import "base";

// Sections ----------------
@import "sections/menu";
@import "sections/footer";
@import "sections/header";


// Special ----------------
@import "special/buttons";
@import "special/deco-parallax";
@import "special/portada";
@import "special/sol-lo";
@import "special/galleries";


// Templates ----------------
@import "templates/landing";
@import "templates/landing-2022";
@import "templates/landing-2024";
@import "templates/landing-2025";
@import "templates/single-legal";
@import "templates/single-artista";
@import "templates/single-galeria";
@import "templates/single-obra";
@import "templates/equipo";
@import "templates/sobre";
@import "templates/comisario";
@import "templates/galerias";
@import "templates/login";
@import "templates/inscripciones";
@import "templates/single-inscripcion";
@import "templates/inscripcion-list";
@import "templates/terms";
@import "templates/eventos-login";
@import "templates/eventos-list";
@import "templates/single-evento";
@import "templates/programas";
@import "templates/programas-single";
@import "templates/manifesto";
@import "templates/recomendaciones";
