header .header__menu {
    display: flex;
    align-items: center;

    &__nav {
        display: flex;
        gap: 1.5em;
        margin-left: 2.5em;
        align-items: center;
        // margin-top: 3rem;
        margin-top: 0.4em;

        &__item {
            // text-indent: -1em;
            // padding-left: 1em;

            &--buy{
                padding: 0.4em 0.6em;
                font-size: 0.95em;
                border: 1px solid white;
                background-color: white;
                color: black;
                // text-align: center;
                // width: min-content;
            
                transition: all 0.3s ease;
                &:hover, &:focus, &:active {
                    background-color: transparent;
                    color: white;
                    opacity: 1;
                }
            }

            &--lang{
                    // padding-left: 2em;
            }
        } 
    }

    #hamburger {

        display: none;

        // position: fixed;
        // position: absolute;
        position: relative;
        // top: 35px;
        // right: 30px;

        @media (max-width: 440px) {
            right: 22px;
        } 

        @media (max-width: 400px) {
            right: 15px;
        } 

        transition: all 0.2s ease-out;
        cursor: pointer;

        /* Styling the hamburger lines */
        .line {
            width: 28px;
            height: 2px;
            margin: 0 0 5px 0;
            background: white;
            transition: all 0.2s ease-out;

            &:last-of-type {
                margin: 0;
            }
        }

        .link {
            position: absolute;
            top: 50%;
            right: 100%;
            margin-right: 30px;
            transform: translateY(-50%);

            font-size: 20px;

            &__button {
                padding: 0.5em 0.9em;
                background-color: white;
                color: black;
                border: 1px solid transparent;
            }

            border: 1px solid white;
            width: max-content;

            transition: all 0.3s ease;

            &:hover, 
            &:focus, 
            &:active {
                opacity: 1;

                .link__button {
                    background-color: transparent;
                    color: white;
                }
            }
            

            @media (max-width: 600px) {
                font-size: 17px;
            } 

            @media (max-width: 540px) {
                width: min-content;
                font-size: 15px;
                text-align: center;
            } 

            @media (max-width: 440px) {
                font-size: 12px;
                margin-right: 22px;
            } 

            @media (max-width: 400px) {
                margin-right: 15px;
            } 
        }
    }

    // animation

    &__nav.expanded {

        visibility: visible;
        max-height: 100%;
        opacity: 1;

        /* Adding transform to the X */
        + #hamburger {

            /* Styling the three lines to make it an X */
            .line:nth-child(1) {
                transform: rotate(45deg) translate(5px, 5px);
            }
            .line:nth-child(2) {
                opacity: 0;
            }
            .line:nth-child(3) {
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }

        .header__menu__nav__item--buy{ 
            display: none;
        }
    }
}

// HAMBURGER COLOR

.template-equipo,
.template-comisario,
.template-landing {
    .header__menu {

        &__nav__item--buy {
            border: 1px solid black;
            background-color: black;
            color: white;
        
            transition: all 0.3s ease;
            &:hover, &:focus, &:active {
                background-color: transparent;
                color: black;
            }
        }

        #hamburger {
            .line {
                background: black,
            }
            .link {
                border: 1px solid $lightgray;

                &__button{
                    background-color: black;
                    color: $lightgray;
                    border: 1px solid transparent;
                }

                &:hover, &:focus, &:active {
                    border: 1px solid black;
                    border: 1px solid transparent;

                    .link__button{
                        color: black;
                        border: 1px solid black;
                    }
                }


            }
        }
    }
}

// MENU NAV RE-STYLING FOR HAMBURGER

@mixin hamburger-nav {
    visibility: hidden;
    position: fixed;

    // animation
    transition: opacity 0.2s ease-out, max-height 0.7s ease-in;
    opacity: 0;
    max-height: 0%;
    overflow: hidden;

    // format
    flex-direction: column;
    top: 75px;
    right: 30px;
    gap: 0.5em;
    font-size: 20px;
    background: black;
    color: white!important;
    padding: 15px;
    align-items: end;

    &__item {

        color: $lightgray;

        &--lang{
            margin-top: 1em;
        }
    } 

    @media (max-width: 600px) {
        font-size: 17px;
        line-height: 1.6em

    } 

}

// CASES IN WHICH WE HAVE HAMBURGER

// landing

.template-landing, 
.template-galerias,
.template-artistas,
.template-single-artista,
.template-comisario,
.template-equipo,
.template-manifesto,
.template-sobre,
.template-single-galeria,
.template-single-inscripciones,
.template-single-obra,
.template-programas-single,
.template-programas,
.template-recomendaciones,
.template-single-recomendaciones,
.template-eventos
 {
    .header__menu {

        &__nav {
            @include hamburger-nav;
        }

        #hamburger {
            display: block;
        }
    }
}

// mobile

@media ($tablet) {
    header .header__menu {

        &__nav {
            @include hamburger-nav;
        }

        #hamburger {
            display: block;
        }
    }
}  