.template-comisario {
    
    .s0 {
        padding-bottom: 0;
        padding-top: 33rem;
        
        &__title {

            z-index: 1;
            position: relative;

            // font-size : $fz-135;
            font-size: 12.5rem;
            line-height : 0.85;
            letter-spacing: -0.02em;

            max-width: 10.5em;
            margin: 0 auto -1.33em auto;

            h2:last-of-type{
                text-align: right;
            }
        }

        .portada {
            width: 79%;

            z-index: 0;
            margin: 0 auto;

            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0) 50%,  rgba(0, 0, 0, 0.3) 100%);
            }
        }
    }

    .s1 {
        .post-portada {

            &__title {
                color: white;
            }
    
            &__wrap {
                background: transparent;
        
                &__links, &__location {
                    display: none;
                }
            }
        }
    }
}