/****************************************************************
/ GENERAL STYLING
/****************************************************************/
html{
    // font-size: clamp(6.25%, 0.85vw, 62.5%);
    font-size: clamp(25%, 0.75vw, 41%);
}
body {
    margin: 0;
    font-family: $f;
    font-weight: 500;
    line-height: 1.25;
    // font rendering an aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    transition: opacity 0.3s ease;
} 

body.hidden{
    opacity: 0;
}

// -----------------
// color conventions
// -----------------

body:not(.template-landing):not(.template-equipo):not(.template-comisario):not(.single-inscripciones) {
    background-color: black;
    color: white;

    header {
        img { filter: invert(1); }
        // background: linear-gradient(black 70%, transparent);
        background-color: black;
    }
    footer {
        background-color: transparent;
    }
}

body.template-landing,
body.template-equipo,
body.template-comisario,
body.single-inscripciones  {
    background-color: $lightgray;
    color: black;

    header {
        // background: linear-gradient($lightgray 70%, transparent);
        background-color: $lightgray;
    }
}

body.template-landing.ed-2022{
    background-color: $oldlightgray;
    header {
        // background: linear-gradient($lightgray 70%, transparent);
        background-color: $oldlightgray;
    }
}

.p-x{
    // padding-left: clamp(1.5rem, 7vw, 40rem);
    // padding-right: clamp(1.5rem, 7vw, 40rem);

    padding-left: clamp(15px, 11vw, 200px);
    padding-right: clamp(15px, 11vw, 200px);


    @media ($tablet) {
        padding-left: clamp(15px, 6vw, 200px);
        padding-right: clamp(15px, 6vw, 200px);
    }

    @media ($mobile) {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.p-xs{
    padding-left: unquote("min(7vw, 150px)");
    padding-right: unquote("min(7vw, 150px)");

    @media ($mobile) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.p-xl{
    padding-left: unquote("min(12vw, 400px)");
    padding-right: unquote("min(12vw, 400px)");

    @media ($mobile) {
        padding-left: 15px;
        padding-right: 15px;
    }
}



.p-y{
    padding-top: 170px;
    padding-bottom: 3rem;

    @media ($mobile) {
        padding-top: 90px;
    }  
}

a {
    text-decoration: none;
    color: inherit;
}

p a{
    text-decoration: underline;
}

a:hover, 
a:active,
a:focus{
    opacity: 0.4;
    transition: opacity 0.3s ease;
}


/****************************************************************
/ UTILITIES
/****************************************************************/

.lazy-image {
    opacity: 0;
    transition: opacity  0.6s ease;
}

.bg-cover{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contain{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.hidden { display: none;}
.readmore { margin: 0 5px;}

.center{
    text-align: center !important;
    max-width: 100% !important;
    margin-bottom: 0 !important;
}

.comma-list {

    a {
        display: inline;
    }

    span::after, a::after {
        content:', ';
    }

    span:last-of-type:after, a:last-of-type:after {
        content:'';
    }
}