/* RECOMENDACIONES */

.evento-main{
  margin-top: 100px;
}

.header-recomendaciones .imag-full{
  height: 400px;
  width: 100%;
  position: relative;
}

.header-recomendaciones .imag-full .title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
}

.header-recomendaciones .imag-full .content-image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.header-recomendaciones .imag-full .content-image .image{
  width: auto;
  height: 100%;
  margin-top: 0;
}

.header-recomendaciones .imag-full .content-image .image img{
  height: 100%;
}

.header-recomendaciones .description{
  max-width: 700px;
  margin: 50px auto;
  padding: 20px 40px;
}

.lista-eventos .category{
  padding: 20px 0;
  color: white;
}

.evento-main .list .title{
  text-align: left;
  margin-top: 20px;
  width: 100%;
}

.list h4{
  margin: 10px 0 20px;
  font-size: 20px;

}

.list .description p{
  font-size: 16px;
}

.evento-main .lista-eventos .list .content-evento .evento-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header-recomendaciones .imag-full {
  height: 80svh;
  width: 100%;
  position: relative;
  padding: 100px 0;
}

.header-recomendaciones .imag-full .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
  width: 100%;
} 

.header-recomendaciones .imag-full .content-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header-recomendaciones .imag-full .content-image .image {
    width: auto;
    height: 100%;
    margin-top: 0;
    display: grid;
}

.header-recomendaciones .imag-full .content-image .image img {
    height: 100%;
    margin: auto;
    object-fit: cover;
    position: absolute;
    width: 100%;
}

.header-recomendaciones .imag-full .content-image .image:after{
  content: "";
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.evento-main h1 {
    font-size: 50px;
    font-weight: 500;
    padding: 0 40px;
}

.header-recomendaciones .description {
    max-width: 700px;
    margin: 0px auto;
    padding: 50px 40px;

    p{
        font-size: 16px;
    }
}

@media(max-width: 990px){
  .header-recomendaciones .description {
    max-width: 100%;
    padding: 20px;
  }

  .header-recomendaciones .imag-full {
    height: 90vh;
    width: 100%;
    position: relative;
    padding: 20px 0;
  }
}

.evento-main .title-list {
  padding: 50px 40px 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid white;

  @media(max-width: 990px){
    padding: 50px 15px 10px;
  }
}

.evento-main .title-list h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.header-recomendaciones h1 {
  font-size: 50px;
  font-weight: 500;
  padding: 0 40px;
  @media(max-width: 990px){
    font-size: 30px;
  }
}

.evento-main .list h3 {
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  width: 80%;
  margin: 0 auto 0;
  align-self: center;
}