.login-main{
    height: 100vh;
    width: 100%;

    .centered{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 350px;
        border: 1px solid white;
        border-radius: 30px;

        h1{
            font-size: 40px;
            text-align: center;
            margin: 20px auto;
            line-height: 41px;
        }

        .form{
            padding: 20px;
            font-size: 16px;

            p{
                margin-bottom: 10px;
            }

            input{
                display: block;
                background-color: rgba(255, 255, 255, 0.7);
                margin-top: 5px;
                border-radius: 60px;
                padding: 5px;
                line-height: 16px;
                width: 100%;
            }

            .button-login{
                width: 100%;

                button{
                    padding: 5px 10px;
                    text-align: center;
                    background-color: white;
                    color: black;
                    border: 1px solid white;
                    width: 100%;
                    border-radius: 30px;
                    margin-top: 10px;
                }

                button:hover{
                    background-color: black;
                    color: white;
                }
            }
        }
    }
}