.template-manifesto {
    .s0 {

        padding-bottom: 0;
        padding-top: 33rem;
        // margin-bottom: 9rem;

        &__title {

            z-index: 1;
            position: relative;

            // font-size : $fz-135;
            font-size: 12.5rem;
            line-height : 0.85;
            letter-spacing: -0.02em;

            max-width: 10.5em;
            margin: 0 auto -1.33em auto;

            h2:last-of-type{
                text-align: left;
            }

            @media ($mobile) {
                width: 85%;
            }
        }

        .portada {
            width: 79%;

            @media ($mobile) {
                width: 100%;
            }
            
            z-index: 0;
            margin: 0 auto;

            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0) 50%,  rgba(0, 0, 0, 0.3) 100%);
            }
        }
        
        &__description {
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: 16rem auto;

            p {
                margin-bottom: 0.5em;
            }
        }
    }

    .s0bis {
        padding-top: 9rem;
        
        &__title {

            z-index: 1;
            position: relative;

            // font-size : $fz-135;
            font-size: 12.5rem;
            line-height : 0.85;
            letter-spacing: -0.02em;

            max-width: 10.5em;
            margin: 0 auto -1.33em auto;

            h2:last-of-type{
                text-align: left;
            }

            @media ($mobile) {
                width: 85%;
            }
        }

        .portada {
            width: 79%;

            @media ($mobile) {
                width: 100%;
            }
            
            z-index: 0;
            margin: 0 auto;

            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0) 50%,  rgba(0, 0, 0, 0.3) 100%);
            }
        }
        
        &__description {
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: 16rem auto;

            p {
                margin-bottom: 0.5em;
            }
        }
    }

    
}