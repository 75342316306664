.footer {
    position: relative;
    z-index: 11;
    background-color: #000;
    color: #fff;

    &__content{
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
    &__title {
        font-size: $fz-36;
        line-height: 1;
        max-width: fit-content;

        @media ($tablet) {
            font-size: 17px;
        }
    }
    &__subtitle {
        text-align: right;
    }
    &__info{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2vw 3vw;
        justify-content: space-between;
         
        .politica, .fair{
            font-size: $fz-16;  
        }

        h4{
            font-weight: bold;
            font-size: $fz-18;
        }
    }

    .newsletter {

        flex-grow: 1;
        max-width: 350px;
        height: 16rem;

        @media ($tablet) {
            max-width: 280px;
        }

        h4{
            font-size: $fz-18;
            font-weight: bold;
            margin-bottom: 0.3em;
        }

        form {
            display: flex;
            border: 1px solid white;

            align-items: center;
            padding: 7px;
            font-size: 12px;
            justify-content: space-between;
        }

        input[type="email"] {
            width: 100%;
            flex-grow: 1;
        }

        input[type="submit"] {
            display: inline-block;
            width: 4rem;

            background-image: url('../img/arrow-white.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            cursor: pointer;
        }

        #mailchimp-success {
            display: none;
        }
        #mailchimp-success.error {
            color: red;
        }
    }

    @media ($mobile) {

        text-align: center;

        &__content{
            grid-template-columns: 1fr;
            gap: 20px;
        }

        &__info{
            flex-direction: column;
        }
                
        &__title {
            margin: 0 auto;
            padding-bottom: 3rem;
        }

        &__title, .fair, .politica {
            padding-bottom: 3rem;
        }

        &__subtitle{
            padding-left: 27vw;
        }

        .newsletter {
            margin: 0 auto;
        }
    }
}