.portada {
    position: relative;
    z-index: 0;
    
    &__image {
        width: 100%;
        max-height: 80vh;
        object-fit: cover;

        @media ($mobile) {
            max-height: unset;
        }
    }

    &::after {
        content:'';
        display: block;
        position: absolute;
        z-index: 2;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background: linear-gradient(to bottom, rgba(0,0,0,.3) 0% ,transparent 30%,transparent 50%, rgba(0, 0, 0, 0.3) 100%);
    }
}

.post-portada {
    position: relative;
    z-index: 1;
    text-align: center;

    &__title {
        font-size: $fz-242;
        text-transform: uppercase;
        color: $lightgray;
        line-height: 0.85;

        margin: -1.43em auto 0 auto;
        padding-bottom: 9rem;
        max-width: 9em;

        // adjust to bottom
        min-height: 2.4em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        h1:nth-of-type(2){
            align-self: end;
        }
    }

    &__wrap {
        // text-align: center;
        background-color: black;
        padding-bottom: 3rem;
        max-width: 1100px;
        margin: 0 auto;
    
        &__location {
            text-transform: uppercase;
            font-size: 5.5rem;
            margin-bottom: 0.5em;
        }
        &__description {
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: 0 auto;

            p {
                margin-bottom: 0.5em;
            }
        }
        &__links{
            display: flex;
            justify-content: center;
            margin: 12rem 5vw;
            flex-wrap: wrap;
            gap: 7rem 12rem;
            
            &__item{
                font-size: $fz-30;
                vertical-align: middle;
                display: inline-block;
            }

            &__item a::before{
                background-image: url('../img/arrow-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 3.6rem;
                height: 3.6rem;
                content:'';
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.3em;
            }

            &__item a:hover, 
            &__item a:active,
            &__item a:focus{
                opacity: 0.4;
                transition: opacity 0.3s ease;

            }
        }
    }
}