.template-single-artista {

    // PORTADA

    .s0 {

        min-height: unquote("min(27vw, 340px)");

        @media ($tablet) {
            min-height: unquote("max(30vw, 140px)");
        }

        .portada__image {
            object-fit: contain;
        }
    }

    // TEXTO

    .s1 {

    }

    // GALERIA

    .s2 {

        padding-bottom: 6rem;
        
        &__wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 8.5rem;
            max-width: 1400px;
            margin: 0 auto 11rem auto;

            @media ($mobile) {
                margin: 0 auto 8.5rem auto;
            }
    
            &__item{
                img {
                    max-height: 70rem;
                    overflow: visible;
                }
    
                display: flex;
                width: fit-content;
                align-items: center;
                overflow: visible;
                a {
                    position: relative;
                    overflow: visible;
                }
            }

            &__item a:hover, 
            &__item a:active,
            &__item a:focus{
                img {
                    filter: grayscale(1);
                    transition: filter 0.6s ease;
                }

                opacity: 0.85;
                transition: opacity 0.6s ease;
            }

            &__item a::after{
                background-image: url('../img/sol.png');
                background-position: bottom;
                background-repeat: no-repeat;
                background-size: contain;
                width: 50rem;
                height: 50rem;
                max-width: 200%;
                // height: 100%;
                // width: 200%;
                content:'';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                // transform: translateX(-50%);
                transform: translateX(-51.4%); // corregir defecto en el PNG
                opacity: 0;
                z-index: -1;
            }

            &__item a:hover::after, 
            &__item a:active::after,
            &__item a:focus::after{
                opacity: 0.7;
                // bottom: -8rem;
                bottom: -15%;
                transition: all 0.6s ease;
            }
        }


    }

    // GALLERIES

    .s3 {

        position: relative;
        color: black;

        &__title {
            background-color: white;
            position: relative;
            height: 23rem;
            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid black;
            border-bottom: none;


            &__sol {
                position: absolute;
                top: -69rem;
                width: 100rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
            }

            &__letters {
                position: relative;
                font-size: 10rem;
                z-index: 1;
            }
        }

        &__wrap {
            // display: grid;
            // grid-template-columns: auto 1fr;
            background-color: $lightgray;
            position: relative;
            z-index: 1;

            // min-height: calc(100vh - 23rem);

            // @media ($mobile) {
            //     grid-template-columns: 1fr;
            //     min-height: unset;
            // }

            > * {
                border: 1px solid black;
            }

            &__list {
                // border-right: none;
                // width: calc(50vw + 3.8rem);
                // min-width: 70rem;
                text-transform: uppercase;

                @media ($mobile) {
                    width: unset;
                }

                &__item{
                    border-bottom: 1px solid black;
                    min-height: 16rem;
                    display: flex;
                    align-items: center;
                    padding: 2.5rem 8rem;

                    position: relative;

                    &__name{
                        font-size: 5.5rem;
                    }
                    &__born{
                        font-size: 3rem;
                    }

                    // transition: width 0.5s cubic-bezier(0.53, 0.05, 0.24, 0.98), 
                    //     background-color 0.7s ease-in,
                    //     color 0.7s ease-in;

                    // transition: all 0.5s ease;

                    width: 100%;
                }


                &__item::after{
                    display: block;
                    content: '';

                    position: absolute;
                    top: 50%;
                    right: 1.4rem;
                    height: 3.8rem;
                    width: 3.8rem;
                    transform: translate(-50%, -50%);

                    background-image: url('../img/arrow-black.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &__name{
                        font-size: 5.5rem;
                    }
                    &__born{
                        font-size: 3rem;
                    }
                }

                &__item:hover, &__item:active, &__item:focus{
                    background-color: black;
                    color: $lightgray;
                    // width: 100vw;
                    opacity: 1;

                    // transition: width 0.7s cubic-bezier(0.53, 0.05, 0.24, 0.98), 
                    //     background-color 0.4s ease,
                    //     color 0.4s ease;

                    &::after{
                        background-image: url('../img/arrow-white.svg');
                    }

                    // @media ($mobile) {
                    //     width: unset;
                    // }
                }
            }

            &__images {

                // @media ($mobile) {
                    display: none;
                // }

            //     position: relative;
            //     height: calc(100vh - 23rem);

            //     background-color: $lightgray;

            //     &__item {
            //         display: block;
            //         position: absolute;

            //         img {
            //             object-fit: cover;
            //             height: calc(100vh - 23rem);
            //             width: calc(50vw - 3.8rem); 
            //         }

            //         right: -120%;
            //         transition: right 0.7s cubic-bezier(0.53, 0.05, 0.24, 0.98);
            //     }

            //     &__item.selected {
            //         // z-index: 2;
            //         right: 0;
            //     }
            }
        }
    }
}