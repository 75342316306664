.template-single-legal {
    .s1 {

        margin: 18rem 3rem 3rem 3rem;

        &__title {
            font-size: 9rem;
            color: $lightgray;
            line-height: 10rem;
            margin-bottom: 3rem;
            margin-top: 5rem;
            text-transform: uppercase;
        }
        
        &__text {
            font-size: 3.6rem;
            line-height: 1.17;

            p, ul, ol {
                margin-bottom: 0.5em;
            }

            ul {
                list-style-type: square;
                margin-left: 1em;
            }


            ol {
                margin-left: 1em;
            }

            strong {
                margin-top: 0.5em;
                display: inline-block;
            }
        }
    }
}
