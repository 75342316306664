.single-evento{
    margin-top: 150px;
    font-size: $fz-18;
    padding: 40px;
    @media(max-width: 990px){
        padding: 40px 10px;
        margin-top: 70px;
    }
    .title{
        margin-top: 50px;
        p{
            font-size: $fz-22;
        }
        h1{
            font-size: $fz-100;
        }

        
    }

    .content-image{
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 2fr 1fr;

        @media(max-width: 990px){
            grid-template-columns: 1fr;
        }

        .fechas{
            display: grid;
            grid-template-columns: 40px 1fr;
            margin-bottom: 15px;
        }

        .button{
            padding: 0 20px;
            font-size: $fz-22;
        }
    }

    .content{
        .subtitle{
            font-size: $fz-18;
            margin-bottom: 30px;
            margin-top: 30px;
        }

        .evento-description{
            margin-bottom: 30px;
            p{
                font-size: $fz-22;
                margin-bottom: 15px;
            }
        }
    }

    .pop-up{
        display: none;
        width: 50%;
        margin: 0 auto;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #000;
        padding: 20px 20px;
        border: 1px solid #fff;
        z-index: 10;   
        h3{
            text-align: center;
        }
        .close{
            text-align: right;
            cursor: pointer;
        }

        .input{
            margin-bottom: 20px;
        }
    }
    
    .pop-up.active{
        display: block;
    }
    
    .pop-up .btn{
        border: 0;
        cursor: pointer;
        width: auto;
        margin-right: 30px;
    }
    
    .pop-up .btn.btn-green{
        border: 0;
        cursor: pointer;
        width: auto;
        margin-right: 30px;
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
    }
    
    .pop-up .buttons{
        display: flex;
    }

    .acompanante_email{
        text-align: center;
        margin-bottom: 15px;
    }
    
    .acompanante_email label{
        display: block !important;
    }
    
    

    form{
        padding: 20px;
        font-size: 16px;
        max-width: 350px;
        margin: auto;
        text-align: center;
        p{
            margin-bottom: 10px;
        }

        input, textarea{
            display: block;
            background-color: rgba(255, 255, 255, 0.7);
            margin-top: 5px;
            border-radius: 60px;
            padding: 5px;
            line-height: 16px;
            width: 100%;
        }

        textarea{
            display: block;
            background-color: rgba(255, 255, 255, 0.7);
            margin-top: 5px;
            border-radius: 20px;
            padding: 5px;
            line-height: 16px;
            width: 100%;
        }

        label{
            display: block;
        }

        .flex{
            display: flex;
        }

        select{
            width: 100px;
            align-self: center;
            background-color: #3c3c3c;
            border-radius: 60px;
            margin-right: 10px;
            padding: 5px;
            cursor: pointer;
        }

        input[type="radio"]{
            background-color: initial;
            cursor: default;
            appearance: auto;
            box-sizing: border-box;
            margin: 3px 3px 0px 5px;
            padding: initial;
            width: auto;
            border: initial;
            display: unset;
        }

        button{
            padding: 5px 10px;
            text-align: center;
            background-color: white;
            color: black;
            border: 1px solid white;
            width: 100%;
            border-radius: 30px;
            margin-top: 30px;
        }

        button:hover{
            background-color: black;
            color: white;
        }
    }
}

.success-message button {
    width: 250px;
    margin: 0 auto;
    padding: 10px 15px;
    border: 1px solid white;
    border-radius: 60px;
    text-align: center;
    font-size: 16px;
    /* height: 30px; */
    line-height: 30px;
    display: block;
    cursor: pointer;
    margin-bottom: 30px;
    color: white;
    margin-top: 20px;
}

.success-message{
    width: 50%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    padding: 20px 20px;
    border: 1px solid white;
    z-index: 10;
    border-radius: 20px;
    color: white;
}

.success-message p{
    font-size: 20px;
    color: white;
}

.success-message .close{
    margin-left: auto;
    text-align: right;
    cursor: pointer;
    color: white;
    font-size: 20px;
    color: white;
}