.inscription-list{
    min-height: 100vh;

    h1{
        font-size: 50px;
        font-weight: bold;
        margin-top: 100px;
        text-align: center;
        margin-bottom: 50px;
    }

    h3{
        font-size: 30px;
        font-weight: bold;
        margin-top: 50px;
        text-align: left;
        margin-bottom: 50px;
        padding-left: 30px;
    }

    .deadline{
        padding-bottom: 100px;
        h3{
            padding: 30px;
            font-size: 30px;
            text-align: left;
            margin-bottom: 10px;
            padding: 0 30px;
        }
        .content-deadline{
            padding: 0 30px;
            p{
                font-size: 16px;
            }
        }
    }

    .inscripcion.titles-list{
        border-top: 0px solid white;
        border-bottom: 1px solid white;
        font-weight: bold;   
        @media(max-width: 767px){
            display: none;
        }
    }

    .inscripcion{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 20px 20px;
        font-size: 16px;
        border-bottom: 1px solid white;
        text-align: center;
        align-content: center;
        a{
            text-decoration: underline;
            @media(max-width: 767px){
                width: 250px;
                padding: 10px 5px;
                border: 1px solid white;
                border-radius: 60px;
                margin: 20px auto 0;
            
            }
        }

        .button {
            display: inline-block;
            cursor: pointer;
            position: relative;
            line-height: 43px;
            height: 43px;
            border-width: 0.06rem;
            border-style: solid;
            border-radius: 0;
            text-transform: uppercase;
            font-weight: 500;
            text-align: center;
            transition: background-color 0.5s ease;
            width: 200px;

            a{
                text-decoration: none;
            }
        }

        .button * {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            background-color: white;
            color: black;


        }

        @media(max-width: 767px){
            grid-template-columns: 1fr;

            .title{
                margin-bottom: 7px;
            }
        }

    }

    .inscripcion.border-top{
        border-top: 1px solid white;
        border-bottom: 1px solid white;   
    }

    .inscripcion:nth-child(3){
        @media(max-width: 767px){
            border-top: 1px solid white;
        }  
    }
}