.inscription-form{
    padding: 100px 40px;
    max-width: 1024px;
    margin: 20px auto;
    h1{
        font-size : 6.2rem;
        line-height : 6.5rem;
        color : $lightgray;
        letter-spacing: -0.01em;
    }

    .facturacion{
        .grid-column{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
        }
        h3{
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            margin-top: 30px;
        }
    }

    .input-field{
        margin-top: 15px;
        font-size: 16px;
    }

    label{
        font-size: 16px;
        margin-bottom: 5px;
        display: block;
    }

    p small{
        font-size: 12px;
    }

    input{
        display: block;
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 5px;
        border-radius: 60px;
        padding: 5px 10px;
        line-height: 16px;
        width: 100%;
        font-size: 16px;
    }

    input[type=checkbox]{
        display: unset;
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 5px;
        border-radius: 60px;
        padding: 0px;
        line-height: 16px;
        width: auto;
        font-size: 16px;
        appearance: checkbox !important;
    }

    textarea{
        display: block;
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 5px;
        border-radius: 20px;
        padding: 5px 10px;
        line-height: 16px;
        width: 100%;
        font-size: 16px;
    }

    .formset{
        font-size: 16px;
    }

    table{
        width: 70%;
        border-collapse:separate; 
        border-spacing: 0 1.3em;
        @media(max-width: 767px){
            width: 100%;
            border-spacing: 0;
        }
        td,th{
            padding: 0 20px 0 0;

            .image{
                label{
                    display: none;
                }
                input[type=checkbox]{
                    display: none;
                }
            }
            
        }

        tr{
            margin-top: 20px;
        }
    }

    .card-title{
        margin-bottom: 10px;
        font-size: 4rem;
        margin-top: 30px;
    }

    .btn{
        padding: 10px;
        border: 1px solid white;
        border-radius: 60px;
        margin-top: 20px;
        width: 200px;
        text-align: center;
    }

    .flex-buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @media(max-width: 767px){
            display: block;
        }
        button{
            width: 250px;
            background-color: white;
            border: 1px solid white;
            border-radius: 60px;
            font-size: 16px;
            color: black;
            padding: 10px;
            text-align: center;
            @media(max-width: 767px){
                margin-bottom: 30px;
                margin: 0 auto 30px;
            }
        }
    }
}