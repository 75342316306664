.evento-main{
    margin-top: 100px;
    .header-eventos{
        h1{
            font-size: 40px;
            text-align: center;
            margin: 20px auto;
            line-height: 41px;
            padding: 40px;
            @media(max-width: 990px){
                padding: 40px 10px;
            }
        }

        .direccion{
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: auto;
        }
    }

    .lista-eventos{
        padding: 40px;
        @media(max-width: 990px){
            padding: 40px 10px;
        }
        .title-list{
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            h3{
                font-size: 4rem;
                line-height: 5rem;
                letter-spacing: -0.01em;
                
            }

            select{
                width: 250px;
                border: 1px solid white;
                font-size: 20px;
                text-align: center;
                height: 35px;
                line-height: 34px;

                @media(max-width: 990px){
                    font-size: 16px;
                }
            }
        }

        h3{
            margin-top: 50px;
            font-size: 25px;        
        }

        .select-events{
            display: flex;
            h3{
                margin-right: 40px;
                cursor: pointer;
            }

            h3.active{
                text-decoration: underline;
            }
        }

        .list{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
            margin-top: 40px;
            @media(max-width: 1024px){
                grid-template-columns: 1fr 1fr;

            }
            @media(max-width: 990px){
                grid-template-columns: 1fr;

            }
            .content-evento{
                .evento-image{
                    position: relative;
                    overflow:hidden;
                    width: 100%;
                    height: 400px;
                    @media(max-width: 990px){
                        height: 250px;
                        
                    }
                    img{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        min-width: 100%;
                        min-height: 100%;
                        width: auto;
                        max-width: unset;
                        height: 100%;
                    }
                }

                .evento-content{
                    background-color: rgba(255, 255, 255, 0.8);
                    color: black;
                    padding: 20px 10px;
                    h3{
                        font-size: 5rem;
                        line-height: 6rem;
                        letter-spacing: -0.01em;
                        margin-bottom: 20px;
                        text-align: center;
                        @media(max-width: 990px){
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }

                    p{
                        font-size: 2.5rem;
                        line-height: 3.4rem;
                        letter-spacing: -0.01em;
                        @media(max-width: 990px){
                           text-align: center;
                        }
                    }

                    .fechas{
                        display: grid;
                        grid-template-columns: 20px 1fr;
                        column-gap: 10px;
                        margin-bottom: 15px;
                        @media(max-width: 990px){
                            grid-template-columns: 1fr;
                            margin-bottom: 5px;
                         }
                        i{
                            font-size: 2.5rem;
                            width: 100%;
                        }

                        svg{
                            width: 100%;
                            height: auto; 
                            @media(max-width: 990px){
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .list.vip.active, .list.others.active{
            display: grid;
        }

        .list.vip, .list.others{
            display: none;
        }
    }
}