@font-face {
    font-family: "GeneralSans";
    src: url("../fonts/GeneralSans-Regular.woff2") format("woff2"),
        url("../fonts/GeneralSans-Regular.woff") format("woff"),
        url("../fonts/GeneralSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "GeneralSans";
    src: url("../fonts/GeneralSans-Medium.woff2") format("woff2"),
        url("../fonts/GeneralSans-Medium.woff") format("woff"),
        url("../fonts/GeneralSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


// @font-face {
//     font-family: 'GeneralSans';
//     src: url('../fonts/GeneralSansVariable-Bold.eot');
//     src: url('../fonts/GeneralSansVariable-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GeneralSansVariable-Bold.woff2') format('woff2'),
//         url('../fonts/GeneralSansVariable-Bold.woff') format('woff'),
//         url('../fonts/GeneralSansVariable-Bold.ttf') format('truetype'),
//         url('../fonts/GeneralSansVariable-Bold.svg#GeneralSansVariable-Bold') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'GeneralSans';
//     src: url('../fonts/GeneralSansVariable-BoldItalic.eot');
//     src: url('../fonts/GeneralSansVariable-BoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GeneralSansVariable-BoldItalic.woff2') format('woff2'),
//         url('../fonts/GeneralSansVariable-BoldItalic.woff') format('woff'),
//         url('../fonts/GeneralSansVariable-BoldItalic.ttf') format('truetype'),
//         url('../fonts/GeneralSansVariable-BoldItalic.svg#GeneralSansVariable-BoldItalic') format('svg');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

