.template-single-obra{

    // PORTADA

    .s0 {

        &__title {

            text-align: center;
            text-transform : uppercase;

            h1 {
                font-size : $fz-135;
                line-height : 0.85;
                margin-bottom: 0.3em;
                letter-spacing: -0.02em;
            }

            h2 {
                font-size : 6.2rem;
                line-height : 6.5rem;
                color : $lightgray;
                letter-spacing: -0.01em;
            }

            margin-bottom: 12rem;
        }

        &__carousel {
            
            position: relative;

            .swiper {
                width: 50vw;
                margin: 0 auto;
                cursor: grab;
                max-height: 90vh;

                @media ($mobile) {
                    width: calc(100% - 50px - 10rem);
                    max-height: unquote("min(450px, 70vh)");
                }

                .swiper-slide {
                    object-fit: contain;
                    max-width: 100%;
                    display: block;
                    max-height: 90vh;

                    @media ($mobile) {
                        max-height: unquote("min(450px, 70vh)");
                    }
                }
            }

            .swiper-navigation {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;

                display: flex;
                justify-content: space-between;

                transition: opacity 0.5s ease;
            }

            // .swiper-navigation.hide { 
            //     pointer-events: none;
            //     opacity: 0;
            // }

            .arrow-left, 
            .arrow-right {
                display: inline-block;
                width: unquote("max(50px, 11rem)");
                padding: unquote("max(12px, 2rem)"); //clickable area bigger
                cursor: pointer;

                svg{
                    stroke: white;
                    stroke-width: 1.5px;
                }

                @media ($mobile) {
                    width: 25px;
                    padding: 0;

                    svg{
                        stroke-width: 2.5px;
                    }
                }
            }

            .arrow-left { text-align: left; }
            .arrow-right { text-align: right; }

            .arrow-left:hover, 
            .arrow-right:hover {
                opacity: 0.4;
                transition: opacity 0.4s ease;
            }

            .swiper-button-disabled.arrow-right,
            .swiper-button-disabled.arrow-left { 
                visibility: hidden;
            }

        }

        &__details {
            display: flex;
            justify-content: center;
            margin: 12rem 5vw;
            flex-wrap: wrap;
            gap: 7rem 12rem;

            text-transform : uppercase;
            
            &__item{
                display: inline-block;
                text-align: left;

                p:first-of-type {
                    font-size: $fz-20;
                    line-height: 1.2;
                    font-weight: 500;
                    letter-spacing: 0.005em;
                }

                p:last-of-type {
                    font-size: $fz-25;
                }
            }
        }

    }

    // TEXTO

    .s1 {

        position: relative;
        overflow: hidden;
        background-color: white;
        color: black;

        padding-top: 15rem;

        &__description {
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: 0 auto;

            p {
                margin-bottom: 0.75em;
            }
        }

    }

}