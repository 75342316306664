.template-equipo {

    .s0 {

        .galleries__title {

            margin-top: 5rem;
            margin-left: -7rem;
            margin-bottom: -9rem;
            height: 1.6em;
            z-index: 1;
            position: relative;

            @media ($mobile) {
                margin-top: 11rem;
                margin-left: 3rem;
                margin-bottom: -8rem;
                font-size: 34px;
            }

        }

        &__teamimage {
            width: 100%;
            display: block;
            @media(max-width: 767px){
                margin: 0 auto;
            }
        }

        .d-flex{
            // display: flex;
            // .image{
            //     width: 50%;
            // }
            // @media ($mobile) {
            //     display: block;

            //     .image{
            //         width: 100%;
            //     }
            // }

        }

        &__wrap {

            width: 930px;
            max-width: calc(100% - 35rem);
            margin: 30px auto;

            @media ($mobile) {
                max-width: calc(100% - 8rem);
            }

            &__item {

                margin-left: 0;
                margin-right: auto;
                width: fit-content;

                position: relative;

                pointer-events: none; // in order to not make all div clickable, but only image/letters

                &__image {

                    pointer-events: all;

                    width: unquote("min(32vw, 680px)");
                    height: unquote("min(25vw, 50px)");
                    display: block;

                    @media ($mobile) {
                        width: 40rem;
                        height: 30rem;
                    }
                }

                &__text {
                    text-transform: uppercase;
                    height: min-content;

                    @media ($mobile) {
                        margin-left: 10rem;

                    }

                    h2 {
                        pointer-events: all;

                        font-size : $fz-7;
                        line-height : 1;
                        letter-spacing: -0.02em;

                        @media ($tablet) {
                            font-size : 9rem;
                        }
        
                        @media ($mobile) {
                            font-size : 26px;
                            font-size: 6.6rem;
                        }
                    }

                    h3 {
                        pointer-events: all;

                        font-size : 4.2rem;
                        line-height : 1.055;
                        display: block;
                        color: white;
                        margin-top: 0.7em;

                        @media ($tablet) {
                            font-size : 3rem;
                        }
                    }
                }
            }

            &__item:nth-of-type(2n) { 

                // margin: 7rem 0 7rem auto;
                margin: 0 0 0 auto;

                @media ($mobile) {
                    margin: 8rem 0 8rem auto;
                }

                justify-content: end;

                .s0__wrap__item__image {
                    margin-right: 0;
                    margin-left: auto;
                }

                .s0__wrap__item__text {
                    left: auto;
                    right: 15rem;

                    @media ($mobile) {
                        margin-left: 10rem;
                        margin-top: 4rem;
                    }

                    max-width: 55rem;

                    @media ($tablet) {
                        max-width: 38rem;
                        margin-left: auto;
                        margin-right: 30px;
                    }

                    text-align: right;

                    top: auto;
                    bottom: -3.5rem;
                    transform: none;

                    h2:first-of-type{
                        margin-right: -1.25em;
                    }

                    h2:last-of-type{
                        margin-right: -0.625em;
                    }                                 
                }
            }


            &__item:nth-of-type(2n+1) { 

                .s0__wrap__item__text {

                    h2:last-of-type{
                        margin-left: 1.1em;
                    }

                    h3 {
                        margin-left: 2.2em;
                    }
                }
            }

            &__item:nth-of-type(3) { 

                .s0__wrap__item__text {
                    max-width: 82rem;
                    top: 42%;

                    @media ($tablet) {
                        max-width: 62rem;
                    }

                    @media ($mobile) {
                        top: 47%;
                        left: 12rem;
                    }

                    h2:first-of-type{
                        margin-left: -0.8em;
                    }

                    h2:last-of-type{
                        margin-left: -1.22em;
                    }

                    h3{
                        margin-left: 0.65em;
                    }
                }
            }
        }
    }
}