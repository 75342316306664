.header {
    position: fixed;
    top: 0;
    transition: all 0.5s ease;
    z-index: 110;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: clamp(6px, 2vw, 40px);

    @media (tablet) {
        padding-right: unquote('max(15px,min(7vw,25px))');
    }
    @media ($mobile) {
        padding-bottom: 13px;
    }

    font-size: $fz-22;
    text-transform: capitalize;

    .header__logo {
        // width: 75rem;
        width: 51rem;

        @media (max-width: 600px) { margin-top: 5px;}  
        @media (max-width: 380px) { margin-top: 9px;}  
        @media (max-width: 360px) { margin-top: 12px;}  
        @media (max-width: 340px) { margin-top: 15px;}  
    }

    a.header__logo:hover,
    a.header__logo:active,
    a.header__logo:focus {
        opacity: 1;
    }
}

body.template-landing .header {

    @media (max-width: 1260px) {
        // font-size: 2.1rem;

        .header__logo {
            // width: 65rem;
        }
    }  

    @media ($tablet) {
        margin-right: 30px;

        .header__logo {
            margin-right: 30rem;
            // width: 59rem;
        }
    }

    .dropdown{
        position: relative;
        ul{
            display: none;
            text-align: right;
        }
    }
    
    .dropdown:hover{
        ul{
            display: block;
        }
    }
    
    
}

body:not(.template-landing) .header {

    .dropdown{
        position: relative;
        ul{
            display: none;
            text-align: right;
        }
    }
    
    .dropdown:hover{
        ul{
            display: block;
        }
    }

    @media (max-width: 1780px) {
        font-size: 3.15rem;

        .header__logo {
            // width: 63rem;
        }
    } 

    @media (max-width: 1640px) {
        font-size: 3rem;

        .header__logo {
            // width: 51rem;
        }
    } 

    @media (max-width: 1500px) {
        font-size: 2.85rem;

        .header__logo {
            // width: 51rem;
        }
    }  

    @media (max-width: 1440px) {
        font-size: 2.5rem;

        .header__logo {
            width: 39rem;
        }
    }  

    @media (max-width: 1260px) {
        font-size: 2.1rem;

        .header__logo {
            width: 29rem;
        }
    }  

    @media ($tablet) {
        margin-right: 30px;

        .header__logo {
            margin-right: 30rem;
            width: 51rem;
        }

        .dropdown{
            position: relative;
            ul{
                display: none;
                text-align: right;
            }
        }
        
        .dropdown:hover{
            ul{
                display: block;
                position: relative;
                text-align: right;
                right: 0;
            }
        }
    }  
}

.template-landing-redux {

    header {
        background-color: transparent;
        margin-bottom: -9rem;
    }
}