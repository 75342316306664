.eventos-code{
    height: 100vh;
    display: grid;
    align-content: center;
    max-width: 1320px;
    justify-content: center;
    margin: auto;
    h1 {
        font-size : $fz-135;
        line-height : 1.5;
        text-align: center;
        margin-bottom: 0.3em;
        letter-spacing: -0.02em;
    }

    form{
        padding: 20px;
        font-size: 16px;
        max-width: 350px;
        margin: auto;
        text-align: center;
        p{
            margin-bottom: 10px;
        }

        input{
            display: block;
            background-color: rgba(255, 255, 255, 0.7);
            margin-top: 5px;
            border-radius: 60px;
            padding: 5px;
            line-height: 16px;
            width: 100%;
        }

        button{
            padding: 5px 10px;
            text-align: center;
            background-color: white;
            color: black;
            border: 1px solid white;
            width: 100%;
            border-radius: 30px;
            margin-top: 10px;
        }

        button:hover{
            background-color: black;
            color: white;
        }
    }

}