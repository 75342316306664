.template-programas {

	margin-top: 160px;
	

	.title-list{
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		padding: 0 40px;
		h3{
			font-size: 4rem;
			line-height: 5rem;
			letter-spacing: -0.01em;
			
		}

		select{
			width: 250px;
			border: 1px solid white;
			font-size: 20px;
			text-align: center;
			height: 35px;
			line-height: 34px;

			@media(max-width: 990px){
				font-size: 16px;
			}
		}
	}

	h3{
		margin-top: 50px;
		font-size: 25px;        
	}

	.select-events{
		display: flex;
		h3{
			margin-right: 40px;
			cursor: pointer;
		}

		h3.active{
			text-decoration: underline;
		}
	}

	.header-eventos{
		padding: 0 40px;
        h1{
            font-size: 40px;
            text-align: center;
            margin: 20px auto;
            line-height: 41px;
            padding: 40px;
            @media(max-width: 990px){
                padding: 40px 10px;
            }
        }

        .direccion{
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: auto;
        }
    }

	.programas{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 15px;
		margin-bottom: 40px;
		padding: 0 40px;
		.programa a{
			display: grid;
			grid-template-rows: auto auto auto;
		}

		h3{
			font-size:36px;
			text-align: center;
			font-weight: 600;
			width: 80%;
			margin: 0 auto 20px;
			align-self: center;
		}

		.fechas{
			padding: 5px 15px;
			border: 1px solid white;
			border-radius: 30px;
			text-align: center;
			font-size: 16px;
			width: 80%;
			margin: 5px auto;
		}

		.programa-image{
			margin-top: 30px;
			height: 500px;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
}

@media(max-width: 990px) {

	.programas-archive {

		margin-top: 50px;
		margin-bottom: 30px;
		

		.programas {
			display: block;
			border-top: none;
			margin-bottom: 0px;
			padding: 15px;
			h3 {
				width: 100%;
			}

			.fechas {
				width: 90%;
				margin: 0 auto;
			}

			.programa {
				
				margin-top: 30px;

				.programa a {

					display: block;

				}				
			}
		}
	}
}