.sol-lo {
    position: absolute;
    bottom: -61rem;
    width: 81rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}

.sol-lo-parent {
    position: relative;
    padding-bottom: 25rem;
    overflow: hidden;
}