.template-sobre {
    .s0 {

        padding-bottom: 0;
        padding-top: 33rem;
        // margin-bottom: 9rem;

        &__title {

            z-index: 1;
            position: relative;

            // font-size : $fz-135;
            font-size: 12.5rem;
            line-height : 0.85;
            letter-spacing: -0.02em;

            max-width: 10.5em;
            margin: 0 auto -1.33em auto;

            h2:last-of-type{
                text-align: right;
            }

            @media ($mobile) {
                width: 85%;
            }
        }

        .portada {
            width: 79%;

            @media ($mobile) {
                width: 100%;
            }
            
            z-index: 0;
            margin: 0 auto;

            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0) 50%,  rgba(0, 0, 0, 0.3) 100%);
            }
        }
        
        &__description {
            font-size: 4rem;
            line-height: 5rem;
            letter-spacing: -0.01em;
            text-align: center;
            width: 90%;
            margin: 16rem auto;

            p {
                margin-bottom: 0.5em;
            }
        }
    }

    .s0bis {
        padding-top: 33rem;
        
        &__map {
            height:60rem;
            height:unquote("min(70rem, 50vh)");
            width: 100%;
            margin-bottom: 9rem;
        }
    }

    .s1 {
        padding-top: 33rem;
        background-color: black;
        color: black;
        position: relative;

        &__map{
            height: 75vh;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
        }

        &__text {

            position: absolute;
            bottom: 10%;
            left: 20%;

            @media ($mobile) {
                bottom: 10%;
                left: 15px;
            }

            &__big{
                font-size: $fz-135;
                line-height : 1;
                letter-spacing: -0.02em;

                h2:last-of-type {
                    margin-left: 18rem;
                }

                @media ($tablet) {
                    font-size: 30px;

                    h2:last-of-type {
                        margin-left: 4rem;
                    }
                }

            }
            &__address{
                position: relative;
                margin-left: 36rem;
                margin-top: 7rem;

                font-size: 4em;
                line-height : 1.25;

                width: fit-content;

                p {
                    width: fit-content;
                }

                p:last-of-type {
                    margin-left: 35rem;
                }
                &__arrow{
                    position: absolute;
                    right: -2rem;
                    top: 50%;
                    transform: translate(100%, -50%);
                    margin-left: 1rem;
                    width: 6rem;
                }

                @media ($tablet) {
                    font-size: 20px;
                    margin-left: 8rem;

                    p:last-of-type {
                        text-align: right;
                    }

                    &__arrow{
                        margin-left: 1rem;
                        width: 6rem;
                    }
                }
            }
        }
    }

    .s2{
        .galleries__title {
            margin-top: 16rem;

            @media ($mobile) {
                margin-left: 0rem;
            }
        }
    }
    .s2__wrap{
    
        max-width: 800px;
        margin: 0 auto;
        padding-top: 6rem;

        &__item {
            margin: 10rem auto;
            position: relative;

            &__text {

                h3 {
                    font-size : 6.2rem;
                    line-height : 1.055;
                    display: block;
                    color: $lightgray;
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;
                    text-transform: uppercase;

                    @media ($tablet) {
                        font-size : 4rem;
                    }
                }

                h4 {
                    font-size: 4rem;
                    line-height: 5rem;
                    letter-spacing: -0.01em;
                    width: 90%;
                    margin: 0 auto;
        
                    p {
                        margin-bottom: 0.5em;
                    }
                }
            }
        }

    }
}