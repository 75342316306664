.template-programas-single{
	margin-top: 160px;
	.cabecera{
    height: calc(100vh-70px);
    .title{
      display: block;
      padding: 40px;
      gap: 40px;
      position: relative;
      height: 100%;
     
      .image-artist{
        position: absolute;
        top: 0;
        width: 50%;
        right: 40px;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: block;
        
      }

      .name{
        width: 70%;
        align-self: start;
        display: block;
        position: relative;
        z-index: 10;

        h1{
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 60px !important;
        }

        .date{
          display: block;
          width: 70%;
          margin-top: 20px;
          margin-left: 40px;
         
          p{
            font-size: 35px;
            font-weight: 200;
            margin: 0;
          }
        }
      }
      
    }

  }

  .flex-buttons{
    display: flex;
    margin-left: 30px;
    .button{
      background-color: #ccc;
      padding: 20px 40px;
      border-radius: 50px;
      margin-left: 20px;
    }
  }

  .content{

  	display: grid;
  	grid-template-columns: 2fr 1fr;
  	grid-gap: 15px;

    h2{
      font-size: 35px;
      margin-bottom: 15px;
    }

  	.description{

  		padding: 0 30px 0 100px;
      font-size: 25px;  
      p {
        margin: 0 0 15px;
      }

      .partner-logo{
        margin: 30px 0;
      }
  	}

    .direccion{
      margin-bottom: 30px;
      font-size: 20px;
    }

  	.dias{
  		align-self: start;
  		width: 80%;
        margin-top: 120px;
  		.dias-click{

  			display: flex;

  			.dia-li{
  				border: 1px solid white;
  				border-radius: 30px;
  				height: 45px;
          line-height: 45px;
          width: 168px;
  				text-transform: uppercase;
  				text-align: center;
  				font-size: 20px;
  				margin-right: 10px;
  				cursor:pointer;
  			}

  			.dia-li.active{
  				background-color: white;
  				color: black;
  			}

  		}

  		.dias-content{
  			margin-top: 30px;
  			li{
  				display: none;
          p{
            font-size: 20px;
          }
  			}

  			li:first-child{
  				display: block;
  			}

  		}
  	}

  }

  .slider-programa, .slider-programa-2{
    padding: 0 0px;
    margin: 30px auto;
    width: 90%;
    column-count: 3;
    gap: 20px;
    figure{
      width: 100% !important;
      height: auto !important;
      margin: 0 0 20px 0;
      cursor: pointer;
    }

    figcaption{
      margin-top: 10px;
      display: none;
    }
    .photo{
      background-size:contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
    }

    button.slick-arrow {
            position: absolute;
            z-index: 3;
            bottom: 50px;
            font-size: 1.1em;
            text-transform: uppercase;
            border-radius: 30px;
            border: none;
            padding: 10px 25px;
            margin: 0 30px;
        }
        button.slick-arrow.prev{left:30px;}
        button.slick-arrow.next{right:30px !important;}
        .slick-prev, .slick-next{top:91%; z-index: 3; font-size: 1em;}
        .slick-next {
            right: 25px;
        }
        .slick-prev{
            left:  25px;
        }
  }

  .video-programa{

  	padding: 0 40px;
  	margin: 30px 0;

  	video{
  		width: 80%;
      margin: 0 auto;
      display: block;

  	}

  }

  .modulo{
      border-top: 1px solid grey;
      margin: 50px 40px;
      display: grid;
      grid-template-columns: 65% 35%;
      padding: 40px 0;

      h3{
        position: relative;
        font-size: 40px;
        z-index: 9999;
        font-family: Helvetica, sans-serif;
        /* max-width: 100%; */
        margin: 0 0 20px;
        width: 90%;
        font-weight: normal;
      }
      .slider-container{
        position: relative;
        margin-top: 50px;
        margin-right: 30px;
        .slider-non{
            margin: 0;

            .slick-slide{
              img{
                width: 100%;
              }
            }
        }

    li:focus {
        outline: -webkit-focus-ring-color auto 0px;
    }
    
    button.slick-arrow {
            position: absolute;
            z-index: 3;
            bottom: 50px;
            font-size: 16px;
            text-transform: uppercase;
            border-radius: 30px;
            border: none;
            padding: 10px 25px;
            margin: 0 30px;
        }
        button.slick-arrow.prev{left:0;}
        button.slick-arrow.next{right:0 !important;}
        .slick-prev, .slick-next{top:91%; z-index: 3; font-size: 1em;}
        .slick-next {
            right: 25px;
        }
        .slick-prev{
            left:  25px;
        }
  }

  .slider-non-programa{
    padding: 0 0px;
    margin: 30px auto;
    width: 90%;
    column-count: 2;
    gap: 20px;
    div{
      img{
        width: 100% !important;
        height: auto !important;
        margin-bottom: 20px;
        //height: 100%;
        cursor: pointer;
      }
    }
  }
      .content{
          margin-top: 50px;
          display: block;
          width: 77%;
          p{
            margin-top: 20px;
            font-size: 16px;
          }
      }

      .flex-image{
        margin-top: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        padding-right: 50px;
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
     
  }

  .speakers{

  	margin: 40px;
  	border-top: 1px solid white;

  	h3{
  		font-size: 40px;
  		text-transform: uppercase;
  		font-weight: 400;
  		margin-bottom: 20px;
        margin-top: 20px;
  	}

  	.speakers-list{

  		width: 100%;
  		display: block;

      li{
        display: flex;
        border-bottom: 1px solid white; 
        padding: 50px 0;

        .slider-non-speakers{
                margin: 0;
                width: 50%;
                .slick-list{
                    height: 100% !important;
                }
                .slick-slide{
                  img{
                    width: 100%;
                  }
                }
            }

        li:focus {
            outline: -webkit-focus-ring-color auto 0px;
        }
        
        button.slick-arrow {
                position: absolute;
                z-index: 3;
                bottom: 50px;
                font-size: 16px;
                text-transform: uppercase;
                border-radius: 30px;
                border: none;
                padding: 10px 25px;
                margin: 0 30px;
            }
            button.slick-arrow.prev{left:0;}
            button.slick-arrow.next{right:0 !important;}
            .slick-prev, .slick-next{top:91%; z-index: 3; font-size: 1em;}
            .slick-next {
                right: 25px;
            }
            .slick-prev{
                left:  25px;
            }
      }

      .text-speaker{
        width: 50%;
        padding: 0 40px;
      }

  		.image-speaker{
  			width: 100%;
  			height: 200px;
  			background-repeat: no-repeat;
  			background-position: center center;
  			background-size: cover;
  		}

  		h4{
  			font-weight: 700;
  			font-size: 40px;
  			margin-bottom: 20px;
            margin-top: 0;
  		}

        p{
            font-size: 20px;
        }

  	}

  }
}

@media(max-width: 990px) {

  .template-programas-single {

    margin: 100px 0;

    .cabecera {

      height: 100%;
      display: block;
      width: 100%;

      .title {

        display: block;
        padding: 15px;
        margin: 0;

        .name {

          width: 70%;

          h1 {
            font-size: 40px !important;
          }

          .date {

            margin: 15px 0;

            p {
              font-size: 22px !important;
            }
          }
        }

        .image-artist {
          position: absolute;
          top: 0;
          width: 60%;
          right: 0px;
          height: 80%;
          background-size: contain;
          background-position: 70% center;
          background-repeat: no-repeat;
          display: block;
        }
      }
    }

    .content {

      display: block;

      .description {

        padding: 0 15px;

        p {
          font-size: 18px;
        }
      }

      .dias {

        .dias-click {

        }

        .dias-content {

        }
      }
    }

    .slider-programa, .slider-programa-2 {

      width: 100%;
      padding: 0 15px;
      align-self: center;
      column-count: 1;

      button.slick-arrow {
        position: absolute;
        z-index: 3;
        bottom: 20px;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 30px;
        border: none;
        padding: 5px 20px;
        margin: 0 15px;
      }

      button.slick-arrow.prev {
        left: 35px;
      }

      button.slick-arrow.next {
        right: 35px !important;
      }

    }

    .video-programa {

    }

    .modulo{ 
      padding: 0;
      margin: 30px auto;
      display: block;
      grid-template-columns: none;
      width: 100%;
      .s1{
        padding: 0 15px;
        width: auto;
      }

      h3{
        position: relative;
        font-size: 2em;
        padding-top: 50px;
        width: 100%;
      }

      .slider-container {
          position: relative;
          margin-top: 50px;
          width: 100%;
      }
      .flex-image{
        grid-template-columns: none;
        padding-right: 0px;
      }
      .content{margin-top: 50px; padding: 0 15px;}
      .slider-container button.slick-arrow {
          position: absolute;
          z-index: 3;
          bottom: 10px;
          font-size: 14px;
          text-transform: uppercase;
          border-radius: 30px;
          border: none;
          padding: 5px 20px;
          margin: 0 15px;
      }
      
    }

    .speakers {
      margin: 40px 20px;
      h3{
        margin: 20px 0 20px;
      }
      .speakers-list {
          width: 100%;
          display: block;
          li {
            display: block;

            .slider-non-speakers {
              margin: 0;
              width: 100%;

              button.slick-arrow {
                  position: absolute;
                  z-index: 3;
                  bottom: 10px;
                  font-size: 14px;
                  text-transform: uppercase;
                  border-radius: 30px;
                  border: none;
                  padding: 5px 20px;
                  margin: 0 15px;
              }

              .slick-list{
                height: 100% !important;
              }

            }

            .text-speaker {
              width: 100%;
              padding: 0;
              margin-top: 20px;
            }
          }
      }


    }
  }

  .pop-up-image{
    display: none;
  }

  .pop-up-image.active{
    display: grid;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    .close{
      justify-self: end;
      font-size: 25px;
      padding: 30px 0px;
      color: white;
      cursor: pointer;
      height: 50px;
      position: fixed;
      right: 30px;
    }
    img{
      max-height: 75vh;
      max-width: 80%;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;
      align-self: center;

    }
  }
}