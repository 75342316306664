.single-inscripciones{
    background-color: white;
    color: black;
    font-size: 16px;
    font-family: 'Helvetica', sans-serif;

    h4{
        font-size: 18px;
    }

    h3{
        font-size: 20px;
        padding: 10px;
        border-bottom: 1px solid black;
    }

    .inscripcion-fields{
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 0px 10px;
        p{
            line-height: 20px;
        }
    }

    .images-artist, .images-gallery{
        margin-top: 30px;
        h4{
            font-size: 20px;
            padding: 10px;
            border-bottom: 1px solid black;
        } 

        .image{
            h4{
                margin-top: 20px;
                margin-bottom: 20px;
                padding: 0 10px;
                border: 0;
                font-weight: bold;
            }

            img{
                display: block;
                padding: 10px;
                border: 1px solid black;
                width: 80%;
                margin: 0 10px;
            }
        }
    }
}