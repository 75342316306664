// Media queries
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 990px)";
$media-mobile: "only screen and (max-width : 767px)";
$media-mobile-sm: "only screen and (max-width : 375px)";

// Colors
$yellow: #fbcc05;
$green: #68b766;
$lightblue: #c2e4f9;
$blue: #3c5da7;
$violet: #cfa5cd;
$pink: #f5b7d4;
$red: #e14c17;
$gray: #d8d8d8;
$oldlightgray: #dad4d2;
$lightgray: #E4E2E2;

// Spacing
// $p-x: clamp(1.5rem, 2.5vw, 30px);

// Fonts
$f: "GeneralSans";

$medium: 400;
$sbold: 500;
$bold: 600;

// Font-sizes
// $fz-16: clamp(0.9rem, 1.2vw, 1.7rem);
// $fz-18: clamp(1.1rem, 1.2vw, 2rem);
// $fz-22: clamp(1.6rem, 1.53vw, 3rem);
// $fz-36: clamp(1.8rem, 2vw, 5rem);
// $fz-60: clamp(2.2rem, 4.2vw, 10rem);
// $fz-100: clamp(4rem, 5.5vw, 11rem);

// cacho: landing, footer, header
$fz-16: clamp(12px, 1.2vw, 17px);
$fz-18: clamp(14px, 1.2vw, 20px);
// $fz-22: clamp(16px, 1.53vw, 35px);
$fz-22: clamp(16px, 3.3rem, 25px); // menu era demasiado grande
$fz-36: clamp(18px, 2vw, 50px);
$fz-60: clamp(22px, 4.2vw, 100px);
$fz-100: clamp(25px, 5.5vw, 110px);
$fz-150: clamp(74px, 10.5vw, 150px);

// elsa
$fz-20: unquote("max(14px, 2rem)");
$fz-25: unquote("max(17px, 2.5rem)");
$fz-30: unquote("max(17px, 3rem)");
$fz-40: unquote("max(20px, 4rem)");
$fz-135: 12rem;
$fz-242: clamp(30px, 8vw, 140px);

$fz-7: 7rem;


// screen sizes
$smaller: 'max-width: 500px';
$mobile: 'max-width: 767px';
$tablet: 'max-width: 1024px';
$laptop: 'max-width: 1440px';