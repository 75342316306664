.template-landing.ed-2024 {

    .hidden-p{
        display: block !important;
    }
    .sol {
        position: absolute;
        // left: 50%;
        left: 50%; // correct placement with letters
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 99;
        top: 50%;
        img {
            width: 40vw;
            display: block;
            height: auto;
            margin: auto;
            position: absolute;
            left: 50%; // correct placement with letters
            transform: translate(-50%, -50%);
            z-index: 10;
            top: 50%;
            
            @media(max-width:767px){
                width: 90vw;
            }
        }

        margin-top: 0;
    }

    .sol.rising {
        animation: none;
        top: 50%;
    }

    .large{
        position: relative;
    }

    .background-degradado{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        position: absolute;
        bottom: 0vh;
        height: 100vh;
        width: 100%;
        z-index: 11;
        background-color: #F7B939;

        @media(max-width: 767px){
            height: 100vh;
        }
    }

    .banner {
        background-color: #E4E2E2;
        padding-top: 20rem;
        height: 100vh;
        position: relative;
        overflow:hidden;
        padding-left: 20px;
        padding-right: 20px;
        @media(max-width: 767px){
            padding-left: 15px;
            padding-right: 15px;
        }
        &__content {
            height: 100%;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr 5vw 1fr;
            @media(max-width: 767px){
                height: auto;
                display: flex;
                flex-direction: column;
                align-self: center;
                margin-bottom: 50px;
            }
            p {
                font-size: $fz-150;
                color: #000;
                line-height: 1;
                @media(max-width: 767px){
                    font-weight: 500;
                    letter-spacing: -1.26px;
                }
                .sub{
                    padding-left: 5vw;
                    display: block;
                    @media(max-width: 767px){
                        padding-left: 0;
                    }
                }
            }
        }
        &__right {
            justify-self: end;
        }
        &__year{
            @media(max-width: 767px){
                order: 4;
            }
        }
        &__date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-row: 1;
            @media(max-width: 767px){
                order: 2;
                margin-bottom: 25vh;
            }
            p:first-of-type {
                padding-top: 0;
                z-index: 11;
            }
            p:nth-child(2) {
                padding-left: 13vw;
            }
        }
        &__year,
        &__name {
            grid-row-start: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p:first-of-type {
                z-index: 11;
            }
        }

        &__name {
            margin-bottom: 10vh;
            padding-right: 10vw;
            @media(max-width: 767px){
                order: 3;
                margin-bottom: 10px;
                padding-right: 0;
                text-align: right;
            }
        }

        &__place {
            text-align: right;
            margin-bottom: 5vh;
            @media(max-width: 767px){
                order: 1;
                margin-bottom: 10px;
                padding-right: 18px;
            }
            p:first-of-type {
                padding-right: 0;
                position: relative;
                z-index: 11;
            }
            p:nth-child(2) {
                padding-left: 13vw;
            }
        }

        
    }
    .description {
        transition: all 0.3s ease;
        position: relative;
        z-index: 11;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &__content {
            padding-top: 8vw;
            padding-bottom: 10vw;
        }
        &__text {
            font-size: $fz-30;
            color: #000;
            text-align: center;
            line-height: 1.17;
            transition: all 0.3s ease;
            p:first-child {
                display: block;
            }

            p:nth-child(2) {
                display: block;
            }
            p:nth-child(3) {
                display: block;
            }
            p:nth-child(4) {
                display: block;
            }

            p {
                display: none;
            }
        }
        .more {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            text-align: center;
            width: 100%;
            font-size: $fz-36;
            padding: 40px 0;
            cursor: pointer;
            .plus {
                display: block;
                border: 2px solid black;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                line-height: 23px;
                margin: auto;
            }
            .less {
                display: none;
                border: 2px solid black;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                line-height: 23px;
                margin: auto;
            }
        }

        .more.active {
            .plus {
                display: none;
            }

            .less {
                display: block;
            }
        }
        &.open {
            .description__text {
                transition: all 0.5s ease;
            }
        }
    }
    .contact {
        position: relative;
        z-index: 11;
        &__content {
            padding-top: 5.5vw;
            padding-bottom: 5.5vw;
        }
        &__title {
            font-size: $fz-60;
            line-height: $fz-60;
            text-align: center;
            padding-bottom: 1em;
        }
        &__text {
            h2 {
                font-size: $fz-60;
                line-height: 1.08;
                color: #000;
                text-align: center;
                margin-bottom: 3.5vw;
            }
            p {
                font-size: $fz-36;
                color: #000;
                text-align: center;
                line-height: 1.17;
            }
        }
    }
    .artistas {
        position: relative;
        z-index: 11;
        background-color: #dad4d2;
        &__content {
            padding-top: 5.5vw;
            padding-bottom: 8vw;
        }
        &__content.provisional {
            padding-top: 5.5vw;
            padding-bottom: 5.5vw;
        }
        &__list {
            list-style-type: none;
            padding: 0;
        }
        &__title {
            font-size: $fz-60;
            line-height: 1;
            color: #000;
            max-width: 20.5vw;
            margin-bottom: 4.5vw;
            display: block;
        }
        &__year {
            text-align: right;
        }
        .artista {
            display: inline-block;
            position: relative;
            z-index: 0;
            font-size: clamp(21px, 2vw, 50px);
            color: #000;
            line-height: 1.17;
            &__image {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                width: 22rem;
                z-index: -1;

                padding-right: 0.4em; // center in respect with text
            }
            &:hover {
                z-index: 99;
                .artista__image {
                    display: block;
                }
            }

            a:hover, 
            a:active,
            a:focus{
                opacity: 1;
    
                // span {
                //     opacity: 0.4;
                //     transition: opacity 0.3s ease;
                // }
            }

            span::after {
                content:', ';
                display: inline;
                padding-right: 0.3em;
            }
        }

        .artista:last-of-type {

            span::after {
                content:'';
            }
        }
    }
    .partners {
        position: relative;
        z-index: 11;
        background-color: black;
        color: white;

        &__content {
            padding-top: 7vw;
            padding-bottom: 8vw;
        }
        &__title {
            font-size: $fz-60;
            line-height: $fz-60;
            // color: #000;
            max-width: 6.7em;
            margin-bottom: 4.5vw;
        }
        &__items {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            // gap: 7vw;
            a {
                display: inline-block;
            }
            img {
                display: inline;
                margin-right: 8em;
                max-height: 18rem;
                max-width: 23rem;
                vertical-align: middle;
                margin-bottom: 5rem;
            }
            .partner__image {
                // align-self: center;
                // margin-bottom: 8em;
            }

            .subtitle {
                font-size: 3.3rem;
                margin: 2em 0 1em 0;
            }
            .partners__type:first-of-type .subtitle {
                margin-top: 0;
            }
        }
    }
}
@media ($mobile) {
    .template-landing {
        .sol{

            top: 50%;
            img {
                width: 70vw;
            }
            &.rising{
                animation: none;
                top: 50%;
            }
           
        }
        .banner{
            padding-top: 20vw;
            height: 410px;
            padding-top: 125px;
            display: grid;
            &__content{
                height: auto;
                grid-template-rows: 1fr auto 1fr;
                p{
                    // font-size: unquote("max(28px, 2.8rem)");
                    line-height: 1.44;
                }
                .deco-parallax{
                    height: auto;
                }
            }
        }
        .description{
            .more{
                padding: 5px 0;
            }

            // &__text {
            //     font-size: 1.8rem;
            // }
        }
        .artistas .artista {

            line-height: 1.33;

            a:active,
            a:focus{
                opacity: 0.4;
            }
        }
       
    }
    
}

