.galleries {
    background-color: #000;
    z-index: 11;
    position: relative;

    &__content {
        padding-top: 5.5vw;
        padding-bottom: 10vw;
    }
    &__title {
        font-size: $fz-60;
        line-height: $fz-60;
        color: #fff;
        max-width: 26vw;
        display: block;
    }
    &__year {
        text-align: right;
    }

    // &__items {
    //     display: grid;
    //     grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    //     justify-items: center;
    // }

    &__items {
        margin-top: 6vw;
        opacity: 0;
        transition: opacity 0.5s ease;
        border-right: 1px solid white;

        .gallery {

            width: 24.8%;
            padding: 0 2.5vw 2.5vw;

            &__title {
                font-size: $fz-22;
                color: #fff;
                margin-bottom: 0.2em;
            }
            &__city {
                font-size: $fz-18;
                color: $lightgray;
                margin-bottom: 0.45em;
            }
            .border {
                border-left: 1px solid #fff;
                position: absolute;
                padding: 0 2.5vw 2.5vw;
                width: 100%;
                height: 102%;
                top: 0;
                left: 0;
                z-index: -1;
            }
            // &:first-of-type,
            // &:nth-child(4n) .border{
            //     border-right: 1px solid #fff;
            // }
    
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    a:hover, 
    a:active,
    a:focus{
        opacity: 1;

        * {
            opacity: 0.4;
            transition: opacity 0.3s ease;
        }
    }
}


@media ($tablet) {
    .galleries{

        &__items{
            .gallery{
                width: 32.9%;
            }
        }
    }
}


@media ($mobile) {
    .galleries{
        &__items{

            .gallery{
                width: 49.4%;

                // &:nth-child(2n) .border {
                //     border-right: 1px solid #fff;
                // }
            }
        }

    }
    .galleries, .artistas, .partners{
        &__title{
            max-width: fit-content;
        }
    }
}


@media ($smaller) {
    .galleries{
        &__items{
            border-width: 0;
            
            .gallery{
                width: 100%;
                padding-bottom: 10rem;

                .border {
                    border-left: 1px solid #fff;
                    border-right: 1px solid #fff;
                    padding-bottom: 10rem;
                }
            }
        }

    }
}