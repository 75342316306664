.terms{
    .image-logo{
        height: 20vh;

        img{
            width: 400px;
            filter: invert(1);
        }
    }

    .terms-content{
        max-width: 1024px;
        margin: 50px auto;
        font-size: 16px;
        padding: 0px 20px;
        h1{
            font-size: $fz-60;
            text-align: center;
            margin-bottom: 50px;
        }
        h2{
            font-size: $fz-40;

        }
        .content{
            p{
                font-size: $fz-22;
                margin-bottom: 20px; 
                margin-top: 0px;
             }
     
             ul{
                 list-style-type: disc;
                 padding-left: 30px;
                 margin-bottom: 30px;
                 font-size: $fz-22;
                 li{
                     margin-bottom: 10px;
                 }
             }
     
             ol{
                 list-style-type: decimal;
                 padding-left: 30px;
                 margin-bottom: 30px;
                 font-size: $fz-22;
     
             }
        }
        

        p{
            margin-top: 50px;
            text-align: center;

            input{
                all: revert;
            }

            small{
                font-size: $fz-18;
                margin-bottom: 20px;
                 
                text-align: center;
            }    
        }

        .button{
            padding: 5px 10px;
            text-align: center;
            background-color: white;
            color: black;
            border: 1px solid white;
            width: 250px;
            border-radius: 30px;
            margin: 20px auto 50px;
            display: block;
        }

        .button:hover{
            background-color: black;
            color: white;
        }
    }
}